<template>
  <div>
    <donor-form
      :donor="donor"
      :submit="addDonor"
    />
  </div>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import DonorForm from '@/common/components/DonationManagement/DonorForm.vue'

export default {
  name: 'AddDonor',
  components: {
    DonorForm,
  },
  data() {
    return {
      donor: {},
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
      successfulOperationAlert,

    }
  },
  methods: {
    addDonor() {
      return this.$portalUsers.post('internalops/addDonor', this.donor).then(res => {
        this.successfulOperationAlert('Donor is added successfully')
        this.$store.commit('donor/setUser', this.donor.email)
        this.$router.push({ name: 'add-donation', params: { user_id: res.data.data.id } })
      })
    },
  },
}
</script>
<style lang="scss">
</style>
